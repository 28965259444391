import {createSlice} from "@reduxjs/toolkit";

import {resetPassword, requestResetPassword} from "http/auth";
import {startLoading, loadingFailed} from "store/helpers/actions";
import {extractError} from "store/helpers";
import analytics from "services/analytics";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    recoverRequest: startLoading,
    recoverSuccess: handlers.recoverSuccess,
    recoverFailure: loadingFailed,
    resetRequest: handlers.resetRequest,
    recoverProcessingSuccess: handlers.recoverProcessingSuccess,
  },
  initialState,
  name: "recovery",
});

export const requestRecover = values => async dispatch => {
  try {
    dispatch(actions.recoverRequest());
    await requestResetPassword(values);
    dispatch(actions.recoverSuccess(values));
    analytics.logEvent("agentAccountPasswordResetStart");
  } catch (error) {
    dispatch(actions.recoverFailure(extractError(error)));
    analytics.logEvent("agentAccountPasswordResetError");
  }
};

export const resetRequest = () => async dispatch => {
  dispatch(actions.resetRequest());
};

export const confirmRecover = values => async dispatch => {
  try {
    dispatch(actions.recoverRequest());
    await resetPassword(values);
    dispatch(actions.recoverProcessingSuccess());
    analytics.logEvent("agentAccountPasswordResetSuccess");
  } catch (error) {
    const errorText = extractError(error);

    dispatch(actions.recoverFailure(errorText));
    analytics.logEvent("agentAccountPasswordResetFailure", {error: errorText});
  }
};

export default reducer;
