import {createSlice} from "@reduxjs/toolkit";

import {startLoading, loadingFailed} from "store/helpers/actions";
import {extractError} from "store/helpers";
import {getUserNotificationSettings} from "http/notificationSettings";
import {logout} from "store/auth/actions";

import {preferencesFormatter} from "./formatters/preferences";
import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getNotificationSettingsRequest: startLoading,
    getNotificationSettingsSuccess: handlers.getNotificationSettingsSuccess,
    getNotificationSettingsFailure: loadingFailed,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.clearState);
  },
  initialState,
  name: "notificationSettings",
});

export const getNotificationSettings = () => async (dispatch, getState) => {
  const companyId = getState().auth.profile.agency.id;

  try {
    dispatch(actions.getNotificationSettingsRequest());
    const {emailNotificationsEnabled, preferences} = await getUserNotificationSettings(companyId);

    dispatch(
      actions.getNotificationSettingsSuccess({
        emailNotificationsEnabled,
        preferences: preferencesFormatter(preferences),
      }),
    );
  } catch (error) {
    dispatch(actions.getNotificationSettingsFailure(extractError(error)));
  }
};

export default reducer;
