import {Loader} from "@findyourcanopy/canopy-ui";
import {node} from "prop-types";
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

const RequireAuth = ({children}) => {
  const location = useLocation();
  const {isAuthChecked, isAuthenticated} = useSelector(state => state.auth);
  const {reason} = useSelector(({locales}) => locales.translations.modules.errorPage.unauthorized);

  if (!isAuthChecked) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate replace to={{pathname: "/auth/login", state: {from: location, reason}}} />;
};

RequireAuth.propTypes = {
  children: node.isRequired,
};

export default RequireAuth;
