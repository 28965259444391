/* eslint-disable react-refresh/only-export-components */
import React, {createContext} from "react";
import {arrayOf, node, oneOfType} from "prop-types";
import {ThemeProvider} from "styled-components";

import useDarkMode from "hooks/useDarkMode";
import defaultTheme from "utils/theme/default";
import darkTheme from "utils/theme/dark";

export const DarkModeContext = createContext(null);

export const DarkModeThemeProvider = ({children}) => {
  const {isDarkMode, toggleDarkMode} = useDarkMode();

  return (
    <DarkModeContext.Provider value={{isDarkMode, toggleDarkMode}}>
      <ThemeProvider theme={isDarkMode ? darkTheme : defaultTheme}>{children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
};

DarkModeThemeProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

export default DarkModeThemeProvider;
