import {createSlice} from "@reduxjs/toolkit";
import {Toaster} from "@findyourcanopy/canopy-ui";

import {
  getInvitationAgentInfo,
  registerAgentByInvite,
  getInvitationAgentInfoByType,
} from "http/agency";
import {register, verifyEmail} from "http/auth";
import {resendVerificationEmail} from "http/user";
import {startLoading, loadingFailed} from "store/helpers/actions";
import {setVerifyEmailError} from "store/helpers/setError";
import {extractError} from "store/helpers";
import {actions as authActions, authenticate} from "store/auth";
import analytics from "services/analytics";
import {logout} from "store/auth/actions";

import {setToken, setRefreshToken} from "../../http";

import initialState, * as handlers from "./handlers";

export const {actions, reducer} = createSlice({
  reducers: {
    registrationRequest: startLoading,
    registrationSuccess: handlers.registrationSuccess,
    registrationFailure: loadingFailed,
    showModal: handlers.showModal,
    returnFormData: handlers.returnFormData,
    resetFormData: handlers.resetFormData,
    resendEmailSuccess: handlers.resendEmailSuccess,
    activateUserSuccess: handlers.activateUserSuccess,
    invitationInfoRequest: handlers.invitationInfoRequest,
    invitationInfoFailure: handlers.invitationInfoFailure,
    getInvitationInfo: handlers.getInvitationInfo,
    getInvitationInfoByType: handlers.getInvitationInfoByType,
    invitationAgentInfoFailure: handlers.invitationAgentInfoFailure,
    clearState: handlers.clearState,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.clearState);
  },
  initialState,
  name: "registration",
});

export const showModal = values => async dispatch => {
  dispatch(actions.showModal({values}));
};

export const sendFormData =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({values: {subscription, ...values}, inviteId, additionalParams}) =>
    async dispatch => {
      try {
        dispatch(actions.registrationRequest());
        if (inviteId) {
          const {password, ...rest} = values;

          const data = await registerAgentByInvite({
            ...rest,
            email: undefined,
            invitationId: inviteId,
            password,
          });

          await dispatch(authenticate({password, email: data.email}));
          dispatch(actions.clearState());
        } else {
          await register({...values, ...additionalParams});
          dispatch(actions.registrationSuccess());
          analytics.logEvent("agentAccountReadyForVerification");
        }
      } catch (error) {
        const errorText = extractError(error);

        dispatch(actions.registrationFailure(errorText));
        analytics.logEvent("agentAccountCreationFailure", {error: errorText});
      }
    };

export const returnFormData = () => async dispatch => {
  dispatch(actions.returnFormData());
};

export const resetFormData = () => async dispatch => {
  dispatch(actions.resetFormData());
};

export const resendEmail =
  ({email}) =>
  async dispatch => {
    try {
      dispatch(actions.registrationRequest());
      await resendVerificationEmail({email});
      dispatch(actions.resendEmailSuccess());
    } catch (error) {
      dispatch(actions.registrationFailure(extractError(error)));
    }
  };

export const activateUser = token => async (dispatch, getState) => {
  const translations = getState().locales.translations.modules.auth.registrationPage.verify;

  try {
    const data = await verifyEmail(token);

    dispatch(actions.activateUserSuccess());
    dispatch(actions.registrationRequest());

    dispatch(authActions.authenticateSuccess(data));
    analytics.logEvent("agentAccountEmailVerified");

    setToken(data.token);
    setRefreshToken(data.refreshToken);
    localStorage.setItem("loginData", JSON.stringify(data));
    analytics.logEvent("agentAccountLoginSuccess");
    analytics.setUserId(data.user.id);

    if (data.agent) {
      analytics.setUserProperties({agentRole: data.agent.role});
    } else {
      analytics.setUserProperties({userType: data.user.type});
    }
  } catch (error) {
    const errorText = setVerifyEmailError(error.status, translations);

    dispatch(actions.registrationFailure(errorText));
    analytics.logEvent("agentAccountEmailVerifiedFailure", {error: errorText});
  }
};

export const getInvitationInfo = id => async dispatch => {
  try {
    dispatch(actions.invitationInfoRequest());

    const data = await getInvitationAgentInfo(id);

    dispatch(actions.getInvitationInfo(data));
  } catch (error) {
    dispatch(actions.invitationInfoFailure(extractError(error)));
  }
};

export const getInvitationInfoByType =
  ({inviteId, inviteType}) =>
  async dispatch => {
    try {
      dispatch(actions.invitationInfoRequest());

      const data = await getInvitationAgentInfoByType({inviteId, inviteType});

      dispatch(actions.getInvitationInfoByType(data));
    } catch (error) {
      dispatch(actions.invitationAgentInfoFailure(extractError(error)));
      Toaster.toastConfig.showError({title: extractError(error.message)});
    }
  };
export default reducer;
