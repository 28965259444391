import {useNavigate} from "react-router-dom";

import {useAppSelector} from "store/hooks";

import {
  ActionsContainer,
  Container,
  ErrorDescription,
  ErrorDetails,
  ErrorIcon,
  ErrorTitle,
  StyledButton,
} from "./styled";

import type {FC} from "react";

interface Props {
  errorMessage: string;
  errorDetails: string;
}

const PageError: FC<Props> = ({errorDetails, errorMessage}: Props) => {
  const navigate = useNavigate();

  const {errors, CTA} = useAppSelector(({locales}) => locales.translations);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleHeadHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <ErrorIcon name="info-default" />
      <ErrorTitle align="center">{errors.somethingWentWrong}</ErrorTitle>
      <ErrorDescription>{errorMessage}</ErrorDescription>
      <ErrorDetails>
        <summary>{CTA.moreDetails}</summary>
        <ErrorDescription align="left">{errorDetails}</ErrorDescription>
      </ErrorDetails>
      <ActionsContainer>
        <StyledButton text={CTA.refresh} onClick={handleRefresh} />
        <StyledButton text={CTA.headHome} type="secondary" onClick={handleHeadHome} />
      </ActionsContainer>
    </Container>
  );
};

export default PageError;
