import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {Toaster} from "@findyourcanopy/canopy-ui";

import {abortRequestError} from "constants/httpErrors";
import analytics from "services/analytics";
import {upload, getDoc} from "http/document";
import {extractError} from "store/helpers";
import {logout} from "store/auth/actions";

import {loadingFailed} from "../helpers/actions";

import {formatData} from "./helpers";
import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    uploadFileRequest: handlers.uploadFileRequest,
    uploadFileSuccess: handlers.uploadFileSuccess,
    uploadFileFailure: handlers.uploadFileFailure,
    updateProgressValues: handlers.updateProgressValues,
    uploadedToTempStorage: handlers.uploadedToTempStorage,
    removeFile: handlers.removeFile,
    getDocFailure: loadingFailed,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.clearState);
  },
  initialState,
  name: "file-upload",
});

const uploadFileSources = {};

export const removeFile = fileName => dispatch => dispatch(actions.removeFile(fileName));

export const uploadFile = file => async dispatch => {
  try {
    dispatch(
      actions.uploadFileRequest({
        file: {name: file.name},
        progress: 0,
        isLoading: true,
        uploadedToTempStorage: false,
      }),
    );

    const {CancelToken} = axios;

    const source = CancelToken.source();

    uploadFileSources[file.name] = source;

    const data = await upload(file, source, (progress, fileName) =>
      dispatch(actions.updateProgressValues({fileName, progress})),
    );

    dispatch(actions.uploadedToTempStorage(file.name));
    dispatch(actions.uploadFileSuccess({...formatData(data), fileName: file.name}));
  } catch (error) {
    if (error && error.message && error.message === abortRequestError) {
      dispatch(actions.uploadFileFailure({message: error.message, fileName: file.name}));
    } else {
      dispatch(actions.uploadFileFailure({message: error.message, fileName: file.name}));
      analytics.logErrorWithLevel("ToastOverlay", error);
      Toaster.toastConfig.showError({title: extractError(error.message)});
    }
  } finally {
    delete uploadFileSources[file.name];
  }
};

export const abortFileUpload = file => () => {
  if (uploadFileSources[file.name]) {
    uploadFileSources[file.name].cancel();
  }
};

export const clearFileUpload = () => (dispatch, getState) => {
  const {
    fileUpload: {data},
  } = getState();

  data.forEach(file => {
    if (file.isLoading) {
      file.abortFunc();
    }
  });

  dispatch(actions.clearUploadedFiles());
};

export const downloadDocument = id => async dispatch => {
  try {
    const page = window.open("", "_self");

    const response = await getDoc(id);

    page.location.href = response;
  } catch (error) {
    dispatch(actions.getDocFailure(extractError(error)));
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error.message)});
  }
};

export default reducer;
