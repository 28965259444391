import styled from "styled-components";
import {Button, Flex, Icon, Text} from "@findyourcanopy/canopy-ui";

export const ActionsContainer = styled(Flex.Row)`
  gap: 10px;
  margin: 10px;
`;

export const Container = styled(Flex.Column)`
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 30px 60px;
`;

export const ErrorDetails = styled.details`
  margin-bottom: 25px;
  max-height: 400px;
  overflow: auto;
`;

export const ErrorIcon = styled(Icon)`
  color: ${({theme}) => theme.error};
  font-size: 120px;
  margin-bottom: 10px;
`;

export const ErrorTitle = styled(Text.Title)`
  color: ${({theme}) => theme.black};
  margin-bottom: 5px;
`;

export const ErrorDescription = styled(Text.MediumText)`
  color: ${({theme}) => theme.text};
  font-size: 20px;
  margin-bottom: 25px;
`;

export const StyledButton = styled(Button)`
  width: 150px;
`;
