import Intercom from "@intercom/messenger-js-sdk";

class IntercomChat {
  constructor() {
    try {
      const appId = import.meta.env.VITE_INTERCOM_API_KEY;
      Intercom({app_id: appId});
    } catch (error) {
      console.error("[IntercomChat]: Failed to load service", error);
    }
  }

  updateUserInfo = profile => {
    try {
      const {
        user: {id: userId, email, fullName, firstName, middleName, lastName, userHash = ""},
        agency: {id: agencyId, name: agencyName},
      } = profile;

      window.Intercom("update", {
        email,
        user_id: userId,
        user_hash: userHash,
        name: fullName,
        firstName,
        middleName,
        lastName,
        agencyId,
        agencyName,
        userType: "AGENT",
      });
    } catch (error) {
      console.error("[IntercomChat]: Failed to update use data", error);
    }
  };

  show = () => {
    try {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    } catch (error) {
      console.error("[IntercomChat]: Failed to show messenger", error);
    }
  };

  hide = () => {
    try {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    } catch (error) {
      console.error("[IntercomChat]: Failed to hide messenger", error);
    }
  };

  open = () => {
    try {
      window.Intercom("show");
    } catch (error) {
      console.error("[IntercomChat]: Failed to open messenger", error);
    }
  };

  close = () => {
    try {
      window.Intercom("hide");
    } catch (error) {
      console.error("[IntercomChat]: Failed to close messenger", error);
    }
  };
}

export default new IntercomChat();
