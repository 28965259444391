import {createSlice} from "@reduxjs/toolkit";

import {logout} from "store/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    setIsUserSnapInitialized: handlers.setIsUserSnapInitialized,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.resetSlice);
  },
  initialState,
  name: "app",
});

export const {setIsUserSnapInitialized} = actions;

export default reducer;
