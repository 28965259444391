import Tracker from "@openreplay/tracker";

class OpenReplay {
  private readonly api: Tracker;

  private isStarted = false;

  constructor() {
    this.api = new Tracker({
      projectKey: import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY,
    });
  }

  /**
   * Track user screen session using open replay.
   */

  async runOpenReplay(profile: {
    agency: {id: string; name: string};
    user: {email: string; id: string};
  }) {
    try {
      if (!this.isStarted) {
        const {agency, user} = profile;

        this.api.start({
          userID: user.email,
          metadata: {
            agentId: user.id,
            agencyId: agency.id,
            agencyName: agency.name,
          },
        });

        this.isStarted = true;
      }
    } catch (error) {
      console.error({error, errorInfo: "[OpenReplay]: Failed to identify the user"});
    }
  }
}

export default new OpenReplay();
