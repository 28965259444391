import "regenerator-runtime";

import {StrictMode} from "react";
import {createRoot} from "react-dom/client";

import {pdfjs} from "react-pdf";
import {Provider as ReduxProvider} from "react-redux";
import {RouterProvider} from "react-router-dom";

import {store} from "store/configureStore";
import "services/performance-monitoring";
import remoteConfig from "services/remote-config";
import "services/user-snap";
import "services/intercom-chat";

import "./Fonts/Fontello/css/fontello.css";
import router from "./router";

window.addEventListener("vite:preloadError", event => {
  event.preventDefault();
  window.location.reload();
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

remoteConfig.setupRemoteConfig();

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
      </ReduxProvider>
    </StrictMode>,
  );
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  );
}
