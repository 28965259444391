import isString from "lodash/isString";

const makeFullName = ({
  firstName = "",
  lastName = "",
  middleName = "",
  fallback = "-",
  withOriginalValues,
}) => {
  const fn = firstName || "";
  const ln = lastName || "";
  let fullName;

  if (isString(fn)) {
    fullName = [fn, middleName, ln].filter(Boolean).join(" ").replace(/\s+/g, " ").trim();
  }

  if (withOriginalValues) {
    return {
      fullName,
      firstName: fn ? fn.toLowerCase() : "",
      lastName: ln ? ln.toLowerCase() : "",
      middleName: middleName ? middleName.toLowerCase() : "",
    };
  }

  return fullName || fallback;
};

export const makeFullNameShortcut = ({firstName = "", lastName = "", defaultValue = ""}) => {
  const fn = firstName || "";
  const ln = lastName || "";
  let shortcut = defaultValue;

  if (isString(fn) && isString(ln)) {
    shortcut = fn.charAt(0) + ln.charAt(0);
  }

  return shortcut;
};

export default makeFullName;
