import {Component} from "react";

import type {ErrorInfo, ReactNode, FC} from "react";

interface State {
  hasError: boolean;
  errorInfo: ErrorInfo;
  errorMessage: string;
}

interface Props {
  children: ReactNode;
  wrapper: FC<{errorMessage: string; errorDetails: string}>;
}

class HOComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorInfo: {componentStack: ""},
      hasError: false,
      errorMessage: "",
    };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, errorInfo: {componentStack: ""}, errorMessage: ""};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(prevState => ({
      ...prevState,
      errorInfo,
      errorMessage: error.message,
    }));

    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    const {hasError, errorInfo, errorMessage} = this.state;
    const {children, wrapper: Wrapper} = this.props;

    if (hasError) {
      return <Wrapper errorMessage={errorMessage} errorDetails={errorInfo.componentStack ?? ""} />;
    }

    return children;
  }
}

export default HOComponent;
