import {showExpireStatus} from "utils/rightToRent/expireStatusHelper";
import makeFullName, {makeFullNameShortcut} from "utils/makeFullName";
import {formatDate, parseAndGetTime} from "utils/date";
import {statuses, types} from "constants/rightToRent";

const getUserData = user => {
  const formattedUser = user ?? {};
  const {fullName, ...userNames} = makeFullName({...formattedUser, withOriginalValues: true});
  const email = formattedUser.email || "";

  return {
    fullName,
    formattedName: makeFullNameShortcut(user),
    email,
    userNames,
  };
};

const formatStatus = ({status, type}) => {
  if (type === types.MANUAL && status === statuses.READY_TO_COMPLETE) {
    return statuses.PROVIDE_IN_PERSON;
  }

  return status;
};

export const formatList = data => ({
  total: data.length,
  list: data.map(({user, branchName, expireDate, status, type, endOfTenancy, ...el}) => {
    const {fullName, formattedName, email, userNames} = getUserData(user);

    return {
      fullName,
      formattedName,
      email,
      branch: branchName,
      branchId: el.branchId,
      renterId: user.id,
      branchConnectionId: el.branchConnectionId,
      status: formatStatus({status, type}),
      endingSoon: showExpireStatus({date: expireDate, status}),
      endOfTenancy: formatDate({date: endOfTenancy, fallback: "-"}),
      expiryDate: formatDate({date: expireDate, fallback: "-"}),
      expiryDateValue: expireDate,
      search: {
        fullName: userNames,
      },
      sort: {
        fullName: fullName.toLowerCase(),
        email: email.toLowerCase(),
        expiryDate: expireDate ? parseAndGetTime(expireDate) : 0,
        endOfTenancy: endOfTenancy ? parseAndGetTime(endOfTenancy) : 0,
        branch: branchName.toLowerCase(),
      },
    };
  }),
});
