import {compareDesc} from "date-fns";

import {RENT_FREQUENCY} from "constants/deprecated-rent-frequency";
import {rpSources, screeningStatuses} from "constants/screeningStatuses";
import {formatDate, parseAndGetTime} from "utils/date";
import makeFullName, {makeFullNameShortcut} from "utils/makeFullName";

const getUserData = user => {
  const formattedUser = user ?? {};
  const {fullName, ...userNames} = makeFullName({...formattedUser, withOriginalValues: true});
  const email = formattedUser.email || "";

  return {
    fullName,
    formattedName: makeFullNameShortcut(user),
    email,
    userNames,
  };
};

const formatInSingleBranchMode = ({total, list}) => ({
  total,
  list: list
    .filter(el => el.isFavourite)
    .map(({user, isFavourite, ...el}) => {
      const {fullName, formattedName, email, userNames} = getUserData(user);
      const isValidAffordability = !Number.isNaN(+el.monthlyRentAffordability);

      const isOverallCompletedStatus = [
        screeningStatuses.ACCEPT,
        screeningStatuses.HIGH_RISK,
        screeningStatuses.CONSIDER,
      ].includes(el.srOverall);

      return {
        fullName,
        formattedName,
        email,
        status: el.srOverall,
        overallReady: el.isCompleted,
        isOverallCompletedStatus,
        referencingType: el.referencingType,
        screeningType: el.screeningType,
        fullReferencingState: el.referencingType,
        affordability: el.monthlyRentAffordability ?? "...",
        tenancyStatus: el.tenancyStatus,
        dateConnected: formatDate({date: el.createdAt}),
        branchConnectionId: el.id,
        renterId: user.id,
        source: [rpSources.GHOST_ADMIN, rpSources.MANUAL_ADMIN, rpSources.MANUAL_AGENT].includes(
          el.source,
        )
          ? rpSources.HQ_INVITE_FILTER
          : el.source,
        favorites: isFavourite,
        paymentFrequency: RENT_FREQUENCY.MONTHLY,
        branchId: el.branchId,
        search: {
          fullName: userNames,
        },
        sort: {
          fullName: fullName.toLowerCase(),
          email: email.toLowerCase(),
          dateConnected: parseAndGetTime(el.createdAt),
          branches: el.branchId.toLowerCase(),
          affordability: isValidAffordability ? +el.monthlyRentAffordability : -1,
        },
      };
    })
    .sort((a, b) => compareDesc(a.sort.date, b.sort.date)),
});

const formatInAllBranchesMode = ({total, list}) => ({
  total,
  list: list
    .filter(el => el.isFavourite)
    .map(({user, isFavourite, ...el}) => {
      const {fullName, formattedName, email, userNames} = getUserData(user);
      const isValidAffordability = !Number.isNaN(+el.monthlyRentAffordability);

      const isOverallCompletedStatus = [
        screeningStatuses.ACCEPT,
        screeningStatuses.HIGH_RISK,
        screeningStatuses.CONSIDER,
      ].includes(el.srOverall);

      return {
        fullName,
        formattedName,
        email,
        status: el.srOverall,
        overallReady: el.isCompleted,
        isOverallCompletedStatus,
        referencingType: el.referencingType,
        screeningType: el.screeningType,
        fullReferencingState: el.referencingType,
        affordability: el.monthlyRentAffordability ?? "...",
        tenancyStatus: el.tenancyStatus,
        branchConnectionId: el.id,
        dateConnected: formatDate({date: el.createdAt}),
        renterId: user.id,
        source: [rpSources.GHOST_ADMIN, rpSources.MANUAL_ADMIN, rpSources.MANUAL_AGENT].includes(
          el.source,
        )
          ? rpSources.HQ_INVITE_FILTER
          : el.source,
        favorites: isFavourite,
        paymentFrequency: RENT_FREQUENCY.MONTHLY,
        branchId: el.branchId,
        search: {
          fullName: userNames,
        },
        sort: {
          fullName: fullName.toLowerCase(),
          email: email.toLowerCase(),
          dateConnected: parseAndGetTime(el.createdAt),
          branches: el.branchId.toLowerCase(),
          affordability: isValidAffordability ? +el.monthlyRentAffordability : -1,
        },
      };
    })
    .sort((a, b) => compareDesc(a.sort.date, b.sort.date)),
});

export const formatFavoritesList = branchId => props =>
  branchId ? formatInSingleBranchMode(props) : formatInAllBranchesMode(props);
