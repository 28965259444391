import {configureStore} from "@reduxjs/toolkit";

import rootReducer from "./reducers";

import type {Action, ThunkAction} from "@reduxjs/toolkit";

export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    preloadedState,
    enhancers: getDefaultEnhancers => getDefaultEnhancers(),
  });

  return store;
};

export const store = makeStore();

export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
