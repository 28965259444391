import {
  ensureInitialized,
  fetchAndActivate,
  getAll,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString,
  getValue,
} from "firebase/remote-config";

import analytics from "services/analytics";
import {initializeFirebaseApp} from "services/firebase";

import {DEFAULTS, FETCH_TIME, LAST_FETCH_STATUSES, MINIMUM_FETCH_INTERVAL} from "./constants";

class RemoteConfig {
  #remoteConfig;

  #checkStatus = async () => {
    try {
      await ensureInitialized(this.#remoteConfig);

      switch (this.#remoteConfig.lastFetchStatus) {
        case LAST_FETCH_STATUSES.FAILURE: {
          await fetchAndActivate(this.#remoteConfig);
          break;
        }

        case LAST_FETCH_STATUSES.NO_FETCH_YET: {
          this.setupRemoteConfig();
          break;
        }

        case LAST_FETCH_STATUSES.SUCCESS: {
          break;
        }

        case LAST_FETCH_STATUSES.THROTTLED: {
          console.error(
            "[Remote Config]: Hit the remote config fetch limits",
            new Error("[Remote Config]: FETCH_THROTTLE"),
          );
          break;
        }

        default: {
          break;
        }
      }

      return this.#remoteConfig.lastFetchStatus;
    } catch (error) {
      console.error("[Remote Config]: Failed to check status", error);

      throw error;
    }
  };

  constructor() {
    const app = initializeFirebaseApp();

    this.#remoteConfig = getRemoteConfig(app);
  }

  setupRemoteConfig = async ({isRetry = false} = {}) => {
    try {
      this.#remoteConfig.settings = {
        fetchTimeMillis: FETCH_TIME,
        minimumFetchIntervalMillis: MINIMUM_FETCH_INTERVAL,
      };
      this.#remoteConfig.defaultConfig = DEFAULTS;

      await fetchAndActivate(this.#remoteConfig);
      analytics.logEvent("remoteConfigWasSetup");
    } catch (error) {
      console.error(
        `[Remote Config]: Failed to${isRetry ? " retry" : ""} setup remote config`,
        error,
      );

      if (!isRetry) {
        await this.setupRemoteConfig({isRetry: true});
      } else {
        throw error;
      }
    }
  };

  getAll = async () => {
    try {
      await this.#checkStatus();

      return getAll(this.#remoteConfig);
    } catch (error) {
      console.error("[Remote Config]: Failed to getAll", error);
      throw error;
    }
  };

  getBoolean = async key => {
    try {
      await this.#checkStatus();

      return getBoolean(this.#remoteConfig, key);
    } catch (error) {
      console.error(`[Remote Config]: Failed to getBoolean ${key}`, error);
      throw error;
    }
  };

  getNumber = async key => {
    try {
      await this.#checkStatus();

      return getNumber(this.#remoteConfig, key);
    } catch (error) {
      console.error(`[Remote Config]: Failed to getNumber ${key}`, error);
      throw error;
    }
  };

  getString = async key => {
    try {
      await this.#checkStatus();

      return getString(this.#remoteConfig, key);
    } catch (error) {
      console.error(`[Remote Config]: Failed to getString ${key}`, error);
      throw error;
    }
  };

  getParsedJSON = async (key, defaultValue = {}) => {
    try {
      await this.#checkStatus();

      const raw = getString(this.#remoteConfig, key);
      let value = defaultValue;

      if (raw) {
        try {
          value = JSON.parse(raw);
        } catch (error) {
          console.error(`[Remote Config]: Failed to parse JSON for ${key}`, error);
        }
      }

      return value;
    } catch (error) {
      console.error(`[Remote Config]: Failed to getParsedJSON ${key}`, error);
      throw error;
    }
  };

  getValue = async key => {
    try {
      await this.#checkStatus();

      return getValue(this.#remoteConfig, key);
    } catch (error) {
      console.error(`[Remote Config]: Failed to getValue ${key}`, error);
      throw error;
    }
  };
}

export default new RemoteConfig();
