import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StyleSheetManager, ThemeProvider as StyledComponentsThemeProvider} from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {CanopyGlobalStyles, Loader, Toaster} from "@findyourcanopy/canopy-ui";
import {ThemeProvider as MUIThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {Outlet, useLocation} from "react-router-dom";

import {FullPageContainer} from "components/Container";
import ErrorBoundary from "components/HOC/ErrorBoundary";
import PageError from "components/HOC/ErrorBoundary/PageError";
import {USER_GROUPS} from "constants/users";
import DarkModeThemeProvider from "context/DarkMode";
import {usePageViews} from "hooks/usePageViews";
import {setIsUserSnapInitialized} from "store/app";
import {restoreAuthentication} from "store/auth";
import {getBranches} from "store/branches/selector";
import {requestVerificationProviderInformation} from "store/company";
import defaultTheme from "utils/theme/default";
import materialTheme from "utils/theme";
import GlobalStyle from "./styled";

import openReplay from "services/open-replay";
import userSnap from "services/user-snap";
import intercomChat from "services/intercom-chat";

// This implements the default behavior from styled-components v5
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
// TODO: Remove after full migration to new API
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const AppContainer = () => {
  const dispatch = useDispatch();
  const {isAuthChecked, isAuthenticated, isGhostModeAuthenticated, profile} = useSelector(
    state => state.auth,
  );
  const {verificationProvider} = useSelector(state => state.company);
  const {pathname} = useLocation();

  const storageListener = useCallback(
    event => {
      if (
        event.key === "loginData" &&
        !/(auth)|(verify-email)|(password-reset)|(server-unavailable)/.test(window.location.href)
      ) {
        dispatch(restoreAuthentication());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, [storageListener]);

  useEffect(() => {
    (async () => {
      const authData = await dispatch(restoreAuthentication());

      // FIXME: Remove request from here. Should be requested down the tree
      if (authData) {
        dispatch(getBranches());
        dispatch(requestVerificationProviderInformation());
      }
    })();
  }, [dispatch]);

  usePageViews();

  const finishUserSnapInitialization = useCallback(() => {
    dispatch(setIsUserSnapInitialized(true));
  }, [dispatch]);

  useEffect(() => {
    if (
      isAuthChecked &&
      isAuthenticated &&
      verificationProvider?.isChatAvailable &&
      !isGhostModeAuthenticated
    ) {
      intercomChat.updateUserInfo(profile);
      intercomChat.show();
    } else if (window.Intercom) {
      intercomChat.hide();
    }
  }, [
    isAuthChecked,
    isAuthenticated,
    verificationProvider?.isChatAvailable,
    profile,
    isGhostModeAuthenticated,
  ]);

  useEffect(() => {
    if (isAuthChecked) {
      if ((isAuthenticated || isGhostModeAuthenticated) && profile) {
        userSnap.initialize(
          profile?.ghostId
            ? {
                user: {
                  agencyId: profile.agency?.id,
                  agentId: profile.agent?.id,
                  userGroup: USER_GROUPS.ADMIN,
                  userId: profile.ghostId,
                },
              }
            : {
                user: {
                  agencyId: profile.agency?.id,
                  agentId: profile.agent?.id,
                  email: profile.user.email,
                  role: profile.agent?.role,
                  userGroup: USER_GROUPS.AGENT,
                  userId: profile.user.id,
                },
              },
          finishUserSnapInitialization,
        );
      } else {
        userSnap.initialize({}, finishUserSnapInitialization);
      }
    }
  }, [
    finishUserSnapInitialization,
    isAuthChecked,
    isAuthenticated,
    isGhostModeAuthenticated,
    profile,
  ]);

  useEffect(() => {
    if (isAuthChecked && isAuthenticated && profile) {
      if (!pathname.includes("/public-rent-passport")) {
        openReplay.runOpenReplay(profile);
      }
    }
  }, [isAuthChecked, isAuthenticated, pathname, profile]);

  if (!isAuthChecked) {
    return (
      <FullPageContainer>
        <Loader />
      </FullPageContainer>
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <MUIThemeProvider theme={materialTheme}>
        <StyledComponentsThemeProvider theme={defaultTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <GlobalStyle />
            <CanopyGlobalStyles />
            <DarkModeThemeProvider>
              <ErrorBoundary wrapper={PageError}>
                <Outlet />
              </ErrorBoundary>
            </DarkModeThemeProvider>
          </LocalizationProvider>
          <Toaster.Container />
        </StyledComponentsThemeProvider>
      </MUIThemeProvider>
    </StyleSheetManager>
  );
};

export default AppContainer;
