import {http, postFileWithProgress} from "..";

export const upload = async (file, source, onProgress, requestType = "right-to-rent") => {
  const data = await http.post(`/documents/tmp/upload`, {
    fileNames: [file.name],
    manualType: requestType,
  });
  const fileData = data[0];

  const preparedData = {
    ...fileData.form.fields,
    acl: "private",
    "Content-Type": file.type,
    "Content-Disposition": encodeURIComponent(file.name),
    file,
  };

  const dataForAmazon = new FormData();

  Object.keys(preparedData).forEach(b => {
    if (b === "file") {
      dataForAmazon.append(b, preparedData[b], preparedData[b].name);
    } else if (b === "bucket") {
      dataForAmazon.append("Bucket", preparedData[b]);
    } else {
      dataForAmazon.append(b, preparedData[b]);
    }
  });

  await postFileWithProgress({
    url: fileData.form.url,
    data: dataForAmazon,
    fileName: file.name,
    source,
    onProgress,
  });

  return fileData;
};

export const getDoc = id => http.get(`/documents/agent/download/${id}`);
