import uppercaseFirstLetter from "utils/uppercaseFirstLetter";
import getTranslations from "utils/locales";
import {backendErrors} from "constants/branchSelector";
import {NavigateFunction} from "react-router-dom";

const locales = getTranslations();

export const formatErrorFromBackend = (error: string = ""): string =>
  error.length > 0 ? `${uppercaseFirstLetter(error)}. ` : "";

interface NavigationProps {
  navigate: NavigateFunction;
}

export const sessionExpired = ({navigate}: NavigationProps): void => {
  navigate("/auth/logout", {
    state: {
      from: window.location.pathname,
      reason: locales.modules.errorPage.unauthorized.reason,
    },
  });
};

export const branchAccessDenied = ({navigate}: NavigationProps): void => {
  navigate("/dashboard/company", {
    state: {
      reason: backendErrors.permissionDeniedToBranch,
    },
  });
};
