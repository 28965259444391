import {useCallback, useEffect} from "react";
import debounce from "lodash/debounce";
import {useLocation} from "react-router-dom";

import analytics from "services/analytics";

export const usePageViews = () => {
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logPage = useCallback(
    debounce(path => analytics.setCurrentScreen(path), 150),
    [],
  );

  useEffect(() => {
    logPage(location?.pathname);
  }, [location?.pathname, logPage]);
};
