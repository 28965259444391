import {createSlice} from "@reduxjs/toolkit";
import {FORM_ERROR} from "final-form";

import {getList, getInvitedList, resendInvite, cancelInvite} from "http/users";
import {userStatus} from "constants/users";
import {startLoading, loadingFailed} from "store/helpers/actions";
import {extractError} from "store/helpers";
import analytics from "services/analytics";
import {logout} from "store/auth/actions";

import {formatUserType, changeResendInviteId} from "./helpers";
import initialState, * as handlers from "./handlers";

const {ACTIVE} = userStatus;

const {actions, reducer} = createSlice({
  reducers: {
    getUsersRequest: startLoading,
    getUsersSuccess: handlers.getUsersListSuccess,
    getUsersFailure: loadingFailed,
    resendInviteSuccess: handlers.resendInviteSuccess,
    cancelInviteSuccess: handlers.cancelInviteSuccess,
    clearList: handlers.clearList,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.clearList);
  },
  initialState,
  name: "usersList",
});

const showFormError = error => ({[FORM_ERROR]: extractError(error)});

export const getUsers =
  ({status, branchId}) =>
  async (dispatch, getState) => {
    const {userRoles} = getState().locales.translations;

    try {
      dispatch(actions.getUsersRequest());
      let data = {};

      if (status === ACTIVE) {
        const response = await getList({branchId});

        data = formatUserType({...response, labels: userRoles});
      } else {
        data = await getInvitedList({branchId});
      }

      dispatch(actions.getUsersSuccess({data, activeStatus: status}));
    } catch (error) {
      dispatch(actions.getUsersFailure(extractError(error)));
    }
  };

export const resendUserInvite = id => async (dispatch, getState) => {
  const {data} = getState().usersList;

  try {
    const response = await resendInvite(id);

    const formattedData = changeResendInviteId(data.list, {oldId: id, newId: response.id});

    dispatch(actions.resendInviteSuccess({total: data.total, list: formattedData}));
    analytics.logEvent("agentInviteResent", {oldId: id, newId: response.id});

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const cancelUserInvite = inviteId => async (dispatch, getState) => {
  const {data} = getState().usersList;

  try {
    await cancelInvite(inviteId);

    const formattedData = data.list.filter(({id}) => id !== inviteId);

    dispatch(actions.cancelInviteSuccess({total: data.total - 1, list: formattedData}));
    analytics.logEvent("agentInviteCancel", {inviteId});

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const {clearList} = actions;

export default reducer;
