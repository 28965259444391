import {createTheme} from "@mui/material";

import defaultTheme from "./default";

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: defaultTheme.primary,
      light: defaultTheme.primaryLight,
      dark: defaultTheme.primaryDark,
    },
    text: {
      primary: defaultTheme.textDark,
      secondary: defaultTheme.text,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: `2px solid ${defaultTheme.textLight}`,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: defaultTheme.font,
  },
});

export default materialTheme;
