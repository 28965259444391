import {addressToString, addressFieldsFormatter} from "utils/addresses";
import {formatDate, parseAndGetTime} from "utils/date";

export const formatBranch = ({id, name, phone, address, numberOfAgents, passports, createdAt}) => {
  const formattedAddress = addressToString(address, addressFieldsFormatter(address));

  return {
    id,
    name,
    phone,
    address: formattedAddress,
    users: numberOfAgents,
    passports,
    dateAdded: formatDate({date: createdAt}),
    sort: {
      name: name.toLowerCase(),
      dateAdded: parseAndGetTime(createdAt),
      address: formattedAddress.toLowerCase(),
    },
  };
};

export const formatBranches = list => list.map(el => formatBranch(el));

export const branchInfoFormatter = ({name, phone, address, dynamicLink}) => ({
  name,
  phone,
  address: addressToString(address, addressFieldsFormatter(address)),
  dynamicLink,
});

export const branchInfoForEditFormatter = ({name, phone, address}) => ({
  name,
  phone,
  address: {
    flat: address.flat,
    houseNumber: address.houseNumber,
    houseName: address.houseName,
    street: address.street,
    district: address.district,
    town: address.town,
    countryCode: address.countryCode,
    postCode: address.postCode,
  },
});

export const overviewFormatter = ({statistics: {agents, rentPassports, tenancies}}) => ({
  users: agents,
  rentPassports: {
    value: rentPassports.completed + rentPassports.inProgress,
    inProgress: rentPassports.inProgress,
    complete: rentPassports.completed,
  },
  tenancies: {
    total: tenancies.completed + tenancies.inProgress,
    inProgress: tenancies.inProgress,
    complete: tenancies.completed,
  },
});

export const formatAvailableBranchesList = branches =>
  branches.map(({id, name, dynamicLink}) => ({
    label: name,
    value: id,
    dynamicLink,
  }));
