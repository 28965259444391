import {createSlice} from "@reduxjs/toolkit";

import * as http from "http/branches";
import {formatBranch} from "http/branches/formatters";
import {startLoading, loadingFailed} from "store/helpers/actions";
import {extractError} from "store/helpers";
import {logout} from "store/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getBranchOverviewRequest: startLoading,
    getBranchOverviewSuccess: handlers.getBranchOverviewSuccess,
    getBranchOverviewFailure: loadingFailed,
    updateBranchSuccess: handlers.updateBranchSuccess,
    clearState: handlers.clearState,
  },
  extraReducers: builder => {
    builder.addCase(logout, handlers.clearState);
  },
  initialState,
  name: "branchDetails",
});

export const getBranchOverview = branchId => async dispatch => {
  try {
    dispatch(actions.getBranchOverviewRequest());

    const data = await http.getBranchInfo({branchId});
    const overview = await http.getBranchOverview(branchId);

    dispatch(actions.getBranchOverviewSuccess({data, overview}));
  } catch (error) {
    dispatch(actions.getBranchOverviewFailure(extractError(error)));
  }
};

export const successUpdateBranch =
  ({branchData}) =>
  async dispatch => {
    const formattedBranch = formatBranch(branchData);

    dispatch(actions.updateBranchSuccess(formattedBranch));
  };

export const clearState = () => dispatch => {
  dispatch(actions.clearState());
};

export default reducer;
