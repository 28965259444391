import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  Outlet,
} from "react-router-dom";

import AppContainer from "./AppContainer";
import RequireAuth from "./RequireAuth";
import {setRouter} from "http";
import {Loader} from "@findyourcanopy/canopy-ui";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppContainer />} hydrateFallbackElement={<Loader />}>
      <Route path="/*" lazy={() => import("pages/Errors/NotFound")} />
      <Route path="/auth/*" lazy={() => import("pages/Auth")} />
      <Route path="/verify-email/*" lazy={() => import("pages/VerifyEmail")} />
      <Route path="/agent/password-reset/*" lazy={() => import("pages/ResetPassword")} />
      <Route path="/public-rent-passport/*" lazy={() => import("pages/PublicRentPassport")} />
      <Route index element={<Navigate replace to="/auth" />} />
      <Route
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }>
        <Route path="/dashboard/*" lazy={() => import("pages/Dashboard")} />
        <Route path="/setup/*" lazy={() => import("pages/AccountSetup")} />
        <Route path="/redirect-from-partner/*" lazy={() => import("pages/RedirectFromPartner")} />
      </Route>

      {/* No match found => show not found page */}
      <Route path="/server-unavailable/*" lazy={() => import("pages/Errors/ServerUnavailable")} />
    </Route>,
  ),
);

setRouter(router);

export default router;
