import {addressToString} from "utils/addresses";

export const formatAddress = list =>
  list.map(address => ({
    label: addressToString(address, ["line1", "street", "town", "postCode", "countryCode"]),
    value: address,
  }));

export const formatLookupAddress = data => ({
  existing: data.propertyAddresses.map(({addressTitle, ...restAddress}) => ({
    label: addressTitle,
    value: restAddress,
  })),
  other: data.lookUpAddresses.map(address => ({
    label: addressToString(address, ["line1", "line2", "line3", "town", "postCode"]),
    value: address,
  })),
});
