import {intervalToDuration, isBefore} from "date-fns";

import {statuses} from "constants/rightToRent";

export const DATE_FORMAT = "dd / MM / yyyy";
export const DATE_FORMAT_SHORT = "dd/MM/yyyy";
export const DATE_FORMAT_MONTH = "MM / yyyy";
export const DATE_TIME_FORMAT = "dd / MM / yyyy h:mm aa";
export const FULL_MONTH_FORMAT = "dd MMM yyyy";

const getDateFromNow = originalDate => {
  const currentDate = new Date();
  const date = new Date(originalDate);

  const isDateBeforeNow = isBefore(date, currentDate);

  // For past dates: calculate from date to current date
  // For future dates: calculate from current date to date (to get positive values)
  const [startDate, endDate] = isDateBeforeNow ? [date, currentDate] : [currentDate, date];

  const {
    years,
    months: originalMonths,
    days,
    hours,
    minutes,
    seconds,
  } = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  // Ensure years and originalMonths are numbers, defaulting to 0 when undefined
  const safeYears = years || 0;
  const safeMonths = originalMonths || 0;
  const safeDays = days || 0;
  const safeHours = hours || 0;
  const safeMinutes = minutes || 0;
  const safeSeconds = seconds || 0;

  const isRestAfterDay = !!(safeHours || safeMinutes || safeSeconds);
  const isRestAfterMonth = !!(safeDays || isRestAfterDay);

  const ceilDays = safeDays + (isRestAfterDay ? 1 : 0);
  const ceilWeeks = Math.ceil(ceilDays / 7);

  const months = safeYears * 12 + safeMonths;

  return {
    months,
    ceilMonths: months + (isRestAfterMonth ? 1 : 0),
    ceilWeeks,
    days: safeDays,
    ceilDays,
    isDateBeforeNow,
  };
};

export const showExpireStatus = ({date, status}) => {
  if (!date || status === statuses.EXPIRED) {
    return false;
  }

  const {isDateBeforeNow, ceilMonths} = getDateFromNow(date);

  // If date is in the past, it's already expired
  if (isDateBeforeNow) {
    return true;
  }

  // For future dates, only show expiring status if within 3 months
  // For future dates, ceilMonths will be positive
  return ceilMonths <= 3;
};

export const formatExpireRtrStatus = ({date, translations}) => {
  if (!date) {
    return null;
  }

  const {ceilMonths, months, ceilWeeks, ceilDays, days, isDateBeforeNow} = getDateFromNow(date);

  // If the date is in the past, it's already expired
  if (isDateBeforeNow) {
    return {
      text: `${translations.expiring} ${translations.today}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  // For future dates, if it's more than 3 months away, don't show any status
  if (ceilMonths > 3) {
    return null;
  }

  // The rest of the function handles future dates within 3 months
  if (ceilMonths >= 2) {
    return {
      text: `${translations.expiringIn} ${ceilMonths} ${translations.months}`,
      type: "warning",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (months === 1 || ceilWeeks > 3) {
    return {
      text: `${translations.expiringIn} ${ceilMonths} ${translations.month}`,
      type: "warning",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (ceilDays >= 14) {
    return {
      text: `${translations.expiringIn} ${ceilWeeks} ${translations.weeks}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (ceilDays >= 2) {
    return {
      text: `${translations.expiringIn} ${ceilDays} ${translations.days}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (days === 1) {
    return {
      text: `${translations.expiringIn} ${ceilDays} ${translations.day}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  return {
    text: `${translations.expiring} ${translations.today}`,
    type: "error",
    iconName: "attention-fill",
    isPrimary: true,
  };
};
